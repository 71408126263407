import React from 'react'

import Home from '@components/pages/home'

// eslint-disable-next-line @thebeansgroup/sb/prefer-import-alias
import environment from '../relay'

const HomePage: React.FC = () => {
  return <Home environment={environment} />
}

export default HomePage
