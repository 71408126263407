import PropTypes from 'prop-types'
import React from 'react'

import * as styles from './styles'

const Main = ({ children }) => <main css={styles.main}>{children}</main>

Main.propTypes = {
  children: PropTypes.any.isRequired
}

export default Main
