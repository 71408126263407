import PropTypes from 'prop-types'
import React from 'react'

import * as styles from './styles'

const Page = ({ children }) => <div css={styles.page}>{children}</div>

Page.propTypes = {
  children: PropTypes.any.isRequired
}

export default Page
