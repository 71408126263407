/* istanbul ignore file */
import PropTypes from 'prop-types'
import React from 'react'
import { QueryRenderer, graphql } from 'react-relay'

import Main from '@components/main'
import Page from '@components/page'
import PageLoading from '@components/page_loading'

const Home = ({ environment }) => (
  <QueryRenderer
    environment={environment}
    query={graphql`
      query homeQuery {
        viewer {
          id
        }
      }
    `}
    render={({ props: queryProps, error }) => {
      if (error) return <div>{error.message}</div>

      if (!queryProps) return <PageLoading />

      return (
        <Page>
          <Main />
        </Page>
      )
    }}
  />
)

Home.propTypes = {
  environment: PropTypes.any.isRequired
}

export default Home
