/* istanbul ignore file */
import cookies from 'js-cookie'
import { Environment, Network, RecordSource, Store } from 'relay-runtime'

export function fetchQuery(operation, variables) {
  const token = cookies.get('sso_token')
  return window
    .fetch(`${process.env.NEXT_PUBLIC_GRAPHQL_URL}/graphql/v1/query`, {
      method: 'POST',
      headers: {
        authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ query: operation.text, variables })
    })
    .then((response) => {
      return response.json()
    })
}

const recordSource = new RecordSource()
const store = new Store(recordSource)
const network = Network.create(fetchQuery)
const environment = new Environment({ network, store })

export default environment
